<label
  class="switch"
  [ngClass]="{ 'switch--disabled': disabled, 'switch--spin': spin }">
  <span class="switch__control">
    <input
      *ngIf="type === 'checkbox'"
      type="checkbox"
      [disabled]="disabled"
      [formControl]="formControl" />
    <input
      *ngIf="type === 'radio'"
      type="radio"
      [disabled]="disabled"
      [formControl]="formControl" />
    <span class="slider round"></span>
  </span>
  <span class="switch__title switch__title--{{ fieldName }}">{{ title }}</span>
</label>
