<div
  class="alert-message"
  [class.alert-message__full-size]="fullSize"
  [class.alert-message--info]="type === 'info'"
  [class.alert-message--warning]="type === 'warning'">
  <div>
    <mat-icon
      *ngIf="type === 'info'"
      class="alert-message__icon"
      svgIcon="error-icon"></mat-icon>
    <mat-icon
      *ngIf="type === 'warning'"
      class="alert-message__icon"
      svgIcon="icon-alert"></mat-icon>
  </div>
  <div>
    <p
      class="alert-message__title"
      *ngIf="title">
      {{ title }}
    </p>
    <p
      class="alert-message__message"
      [innerHTML]="message ? (message | safe: 'html') : 'No data.'"></p>
  </div>
</div>
