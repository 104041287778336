import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { Moment } from 'moment-timezone/moment-timezone';
import { BehaviorSubject, EMPTY, fromEvent, merge, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap } from 'rxjs/operators';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TPortalType } from '@core/enums/portal-type.enum';
import { UserScope } from '@core/enums/user-scope.enum';

import { TokenApi } from '../api/token.api';
import { RouterConstants } from '../constants/router.constants';
import { Feature } from '../enums/feature.enum';
import { TPage } from '../enums/page.enum';
import { StorageItem } from '../enums/storage-item.enum';
import { Credentials } from '../interfaces/session/credentials.interface';
import { ISession } from '../interfaces/session/session-data.interface';
import { ResetPassword } from '../interfaces/user-profile/reset-password.interface';
import { UserStore } from '../store/user/user.store';
import { AppStorage } from '../utils/storage';
import { ApplicationInsightsService } from './application-insights/application-insights.service';
import { FeatureService } from './feature.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  appConfig = inject(APP_CONFIG);
  authorized$: BehaviorSubject<boolean> = new BehaviorSubject(this.isAuthorized());
  tokenRefreshed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // Pending request subjects
  refreshTokenInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshTokenSubject$$: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);
  refreshTokenSubject$ = this.refreshTokenSubject$$.asObservable();

  tokenResolverChecked$ = new BehaviorSubject<boolean>(false);

  tokenRenewTimeoutId: number;

  constructor(
    private token: TokenApi,
    private router: Router,
    private featureService: FeatureService,
    private userStore: UserStore,
    private readonly applicationInsightsService: ApplicationInsightsService,
  ) {}

  init(): void {
    const events = ['online'];
    const eventStreams = events.map(event => fromEvent(window, event));
    const allEvents$ = merge(...eventStreams, fromEvent(document, 'visibilitychange'));

    allEvents$.pipe(
      filter(event => {
        if (event.type === 'visibilitychange') {
          return document.visibilityState === 'visible';
        } else {
          return true;
        }
      }),
      switchMap(() => this.setupTokenRenewRoutine()),
    ).subscribe(timeout => {
      if (timeout) {
        this.setTokenRefreshTimeout(timeout);
      }
    });

    fromEvent(window, 'offline').subscribe(() => {
      if (this.tokenRenewTimeoutId) {
        clearTimeout(this.tokenRenewTimeoutId);
      }
      this.tokenResolverChecked$.next(false);
    });
  }

  setupTokenRenewRoutine(options: {updateRefreshMeta: boolean} = { updateRefreshMeta: false }): Observable<number> {
    if (this.isAuthorized()) {
      const refreshTokenMeta = AppStorage.get(StorageItem.RefreshTokenMeta);
      const renewTimeout = this._getRenewTimeout();

      if (!refreshTokenMeta || !refreshTokenMeta.hasOwnProperty('refreshInMs')) { // eslint-disable-line
        options.updateRefreshMeta = true;
      }

      if (options.updateRefreshMeta) {
        AppStorage.set(StorageItem.RefreshTokenMeta, {
          refreshInMs: renewTimeout,
        });
      }

      if (renewTimeout === 0) {
        return this.refreshToken().pipe(
          switchMap(() => this.setupTokenRenewRoutine({ updateRefreshMeta: true })),
        );
      } else {
        this.tokenResolverChecked$.next(true);
        return of(renewTimeout);
      }
    } else {
      this.tokenResolverChecked$.error(null);
      return EMPTY;
    }
  }

  setTokenRefreshTimeout(timeout: number): void {
    if (this.tokenRenewTimeoutId) {
      clearTimeout(this.tokenRenewTimeoutId);
    }

    this.tokenRenewTimeoutId = Number(
      setTimeout(
        () =>
          this.refreshToken()
            .pipe(
              map(session => !!session),
              switchMap(() => this.setupTokenRenewRoutine({ updateRefreshMeta: true })),
            )
            .subscribe(newTimeout => {
              this.setTokenRefreshTimeout(newTimeout);
            }),
        timeout,
      ),
    );
  }

  setSessionData(session: ISession): void {
    this.userStore.setSession(session);
  }

  goToInitialState(): Promise<boolean> {
    return this.router.navigate(this.getAllowedInitialState());
  }

  getAllowedInitialState(): TPage[] {
    if (this.appConfig.portalType === TPortalType.TechPortal || this.appConfig.portalType === TPortalType.MJC) {
      return [TPage.App, TPage.Dashboard];
    }
    if (this.featureService.allowed(Feature.ViewDashboard)) {
      return [TPage.App, TPage.Dashboard];
    } else if (this.featureService.allowedSome([Feature.ViewClaims, Feature.ViewMyClaims])) {
      return [TPage.App, TPage.Claims];
    } else if (this.featureService.allowed(Feature.UploadAnyClaimFiles)) {
      return [TPage.App, TPage.ClaimDocuments];
    } else if (this.featureService.allowedSome(RouterConstants.features.admin)) {
      return [TPage.App, TPage.Admin];
    } else if (this.featureService.allowedSome(RouterConstants.features.tenants)) {
      return [TPage.App, TPage.Portals];
    } else if (this.featureService.allowedSome([Feature.ManageDataImport])) {
      return [TPage.App, TPage.DataImport];
    } else {
      return [TPage.App, TPage.Initial];
    }
  }

  authorize(credentials: Credentials): Observable<boolean> {
    return this.token.acquire(credentials).pipe(switchMap(session => this.resolveTenantIdentifier(session)));
  }

  refreshToken(): Observable<ISession> {
    const session = this.userStore.getSessionStorage();
    if (session && session.refreshToken && !this._isRefreshTokenExpired()) {
      return this.renewToken(session.refreshToken);
    } else {
      this.unauthorize();
      return EMPTY;
    }
  }

  renewToken(token: string): Observable<ISession> {
    return this.token.renew(token).pipe(
      map(session => {
        if (session.roles === null) {
          throw new Error('Session is broken. Roles are empty');
        } else {
          return session;
        }
      }),
      map(session => {
        const {
          XTenantId,
          XUserId,
          userFirstName,
          userLastName,
          username,
          timeZone,
          phoneNumber,
          impersonatingFullName,
        } = this.userStore.get('session') || {};
        this.setSessionData({
          ...session,
          impersonatingFullName: impersonatingFullName || session.impersonatingFullName,
          userFirstName: userFirstName || session.userFirstName,
          userLastName: userLastName || session.userLastName,
          username: username || session.username,
          timeZone: timeZone || session.timeZone,
          phoneNumber: phoneNumber || session.phoneNumber,
          XTenantId: XTenantId || session.XTenantId,
          XUserId: XUserId || session.XUserId,
        });
        this.tokenRefreshed$.next(true);
        this.refreshTokenInProgress$.next(false);
        this.refreshTokenSubject$$.next(session);
        return session;
      }),
      catchError(error => {
        this.refreshTokenInProgress$.next(false);
        this.unauthorize();
        return throwError(error);
      }),
    );
  }

  resetPassword(credentials: ResetPassword): Observable<boolean> {
    return this.token.resetPassword(credentials).pipe(switchMap(session => this.resolveTenantIdentifier(session)));
  }

  activateAccount(credentials: ResetPassword, url: string): Observable<boolean> {
    credentials.email = credentials.email.trim();
    return this.token
      .activateAccount(credentials, url)
      .pipe(switchMap(session => this.resolveTenantIdentifier(session)));
  }

  logout(): void {
    this.userStore.removeFive9Chat();
    if (this.isAuthorized()) {
      this.token.logout().pipe(
        finalize(() => {
          this.unauthorize();
        }),
      ).subscribe();
    } else {
      this.unauthorize();
    }
  }

  unauthorize(): void {
    this.authorized$.next(false);
    this.clearSession();
    this.applicationInsightsService.clearAIUserId();
    this.userStore.removeFive9Chat();
    window.location.href = window.location.origin;
  }

  clearSession(): void {
    AppStorage.remove(StorageItem.Session);
    AppStorage.remove(StorageItem.Lookups);
    AppStorage.remove(StorageItem.RefreshTokenMeta);
  }

  getToken(): string {
    const session = this.userStore.getSessionStorage();
    return session && session.token;
  }

  getTenantVanityUrlIdentifier(): string {
    const session = this.userStore.getSessionStorage();
    return session && session.vanityUrlIdentifier;
  }

  isAuthorized(): boolean {
    return !!this.getToken();
  }

  resolveTenantIdentifier(session: ISession): Observable<boolean> {
    if (session) {
      const { tenant, env, portalBaseUrl, protocol } = this.getPortalUrlData();
      const vanityUrlIdentifier = session.vanityUrlIdentifier ? session.vanityUrlIdentifier : '';
      // tenant = 'consumer';
      if (this.appConfig.portalType !== TPortalType.TechPortal && tenant !== vanityUrlIdentifier) {
        let url = this._parsePortalUrl(tenant, env, portalBaseUrl, vanityUrlIdentifier);
        url = `${protocol}//${url}/auth?token=${session.refreshToken}`;
        window.location.href = url;
        return of(false);
      } else {
        this.setSessionData(session);
        this.authorized$.next(true);
        return of(true);
      }
    } else {
      return of(false);
    }
  }

  getLoginAsTenantUrl(vanityUrlIdentifier: string, targetUserScope: UserScope = null): string {
    vanityUrlIdentifier = vanityUrlIdentifier || '';
    const {
      tenant, env, portalBaseUrl, protocol, domainName,
    } = this.getPortalUrlData();
    const url = window.location.host;

    let targetPortalBaseUrl: string;
    let targetDomainName: string;
    switch (targetUserScope) {
      case UserScope.Jewelry:
        targetPortalBaseUrl = this.appConfig.facetPortalBaseUrl;
        targetDomainName = this.appConfig.mjcDomain;
        break;
      case UserScope.Tech:
        targetPortalBaseUrl = this.appConfig.techPortalBaseUrl;
        targetDomainName = this.appConfig.defaultDomain;
        break;
      default:
        targetPortalBaseUrl = this.appConfig.defaultPortalBaseUrl;
        targetDomainName = this.appConfig.defaultDomain;
    }

    const parsedUrl = url.replace(
      `${tenant}${portalBaseUrl}${env}.${domainName}`, `${vanityUrlIdentifier}${targetPortalBaseUrl}${env}.${targetDomainName}`,
    );
    return `${protocol}//${parsedUrl}`;
  }

  getPortalUrlData(): {tenant: string; env: string; portalBaseUrl: string; protocol: string; domainName: string} {
    const {
      portalBaseUrl,
      domainOverridesSource,
      domainOverridesDestination,
    } = this.appConfig;
    let hostname = `${window.location.host}`;
    let port = window.location.port;
    if (
      (domainOverridesSource && domainOverridesSource[0] !== '$')
      && (domainOverridesDestination && domainOverridesDestination[0] !== '$')
      && hostname.includes(domainOverridesSource)
    ) {
      hostname = domainOverridesDestination;
      const parsedPort = domainOverridesDestination.split(':').pop();
      port = new RegExp('^[0-9]{0,4}$', 'g').test(parsedPort) ? parsedPort : '';
    }
    const protocol = `${window.location.protocol}`;
    const tenantRegexp = new RegExp(`(.*)${portalBaseUrl}`, 'g');
    const envRegexp = new RegExp(`${portalBaseUrl}(.*?)\\.`, 'g');
    const domainNameRegexp = new RegExp('^(.*\\.)?(.*)((\\..*)|\:[0-9]{0,4})$', 'g'); // eslint-disable-line
    let domainName = domainNameRegexp.exec(hostname)[2];
    const envMatch = envRegexp.exec(hostname);
    const env = envMatch && envMatch[1] ? envMatch[1] : '';
    if (port) {
      domainName = `${domainName}:${port}`;
    }
    const tenantMatch = tenantRegexp.exec(hostname);
    const tenant = tenantMatch && tenantMatch[1];
    return {
      tenant,
      env,
      portalBaseUrl,
      protocol,
      domainName,
    };
  }

  setDefaultHttpHeaders(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const session = this.userStore.getSessionStorage();
    const token = session?.token;

    if (token && (req.url.match(this.appConfig.apiBaseUrl))) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      if (!(req.url.includes('token') && req.method === 'DELETE')) {
        if (session.XTenantId) {
          headers['X-TenantId'] = `${session.XTenantId}`;
        }

        if (session.XUserId) {
          headers['X-UserId'] = `${session.XUserId}`;
        }
      }

      return next(
        req.clone({
          setHeaders: {
            ...headers,
            Authorization: req.headers.get('Authorization') || headers.Authorization,
          },
        }),
      );
    } else {
      return next(req);
    }
  }

  private _parsePortalUrl(tenant: string, env: string, portalBaseUrl: string, vanityUrlIdentifier: string): string {
    let url = `${window.location.host}`;
    if (!tenant) {
      const isSubDomain = url.includes(portalBaseUrl);
      tenant = vanityUrlIdentifier;
      url = isSubDomain ? `${tenant}${url}` : `${tenant}${portalBaseUrl}${env}.${url}`;
    }
    return url.replace(`${tenant}${portalBaseUrl}`, `${vanityUrlIdentifier}${this.appConfig.portalBaseUrl}`);
  }

  /**
   * Get time for the next renew token call
   * Note: maxTimeout is set up to 2147483647. This is due to setTimeout using a 32 bit int
   * to store the delay so the max value allowed would be
   * @param maxTimeout Max timeout in ms
   */
  private _getRenewTimeout(maxTimeout: number = 2147483647): number {
    const minTimeout = 60000; // 1 minutes in milliseconds

    const refreshTokenMeta = AppStorage.get(StorageItem.RefreshTokenMeta);
    const expirationDifference: number = this._getExpirationDiff();

    let result = expirationDifference / 2;

    if (refreshTokenMeta && refreshTokenMeta.hasOwnProperty('refreshInMs')) { // eslint-disable-line
      result = expirationDifference - refreshTokenMeta.refreshInMs;
    }

    if (result < minTimeout) {
      result = 0;
    } else if (result > maxTimeout) {
      result = maxTimeout;
    }

    return result;
  }

  private _getTokenExpiredTime(): Moment {
    const session = this.userStore.getSessionStorage();
    return moment(session.expires).utc();
  }

  private _getExpirationDiff(): number {
    return this._getTokenExpiredTime().diff(moment().utc(), 'ms');
  }

  private _isRefreshTokenExpired(): boolean {
    const minExpirationDifference = 60000; // 1 minutes in milliseconds
    const session = this.userStore.getSessionStorage();
    const expirationDifference = moment(session.refreshExpires).utc().diff(moment().utc(), 'ms');

    return expirationDifference < minExpirationDifference;
  }
}
