import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { PipesModule } from '@shared/pipes/pipes.module';

type TAlertMessageType = 'error' | 'info' | 'success' | 'warning';

@Component({
  selector: 'app-alert-message',
  standalone: true,
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    MatIconModule,
    PipesModule,
  ],
})
export class AlertMessageComponent {
  @Input() title = '';
  @Input() message = '';
  @Input() type: TAlertMessageType = 'info';
  @Input() fullSize = false;

}
