import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { distinctUntilChanged, filter, takeWhile } from 'rxjs/operators';

import { DEFAULT_APP_LOADING_ERROR_MESSAGE } from '@core/constants/app.constants';

interface IApplicationLoaderState {
  showLoader: boolean;
  errorMessage: string;
}

@Component({
  selector: 'app-application-loader',
  standalone: true,
  templateUrl: './application-loader.component.html',
  styleUrls: [],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    LetDirective,
    PushPipe,
  ],
})
export class ApplicationLoaderComponent {
  showLoader$ = this.state.select('showLoader');
  errorMessage$ = this.state.select('errorMessage');

  constructor(private router: Router, private state: RxState<IApplicationLoaderState>) {
    this.state.set({
      showLoader: true,
      errorMessage: '',
    });

    this.state.connect(
      this.router.events.pipe(
        filter(e => (
          e instanceof NavigationStart
            || e instanceof NavigationEnd
            || e instanceof NavigationCancel
            || e instanceof NavigationError
        )),
        distinctUntilChanged(),
        takeWhile(routeEvent => !(routeEvent instanceof NavigationEnd), true),
      ),
      (oldState, routeEvent) => {
        let errorMessage = '';
        if (routeEvent instanceof NavigationError) {
          errorMessage = routeEvent.error?.error?.displayMessage || DEFAULT_APP_LOADING_ERROR_MESSAGE;
        }

        return {
          ...oldState,
          showLoader:
            routeEvent instanceof NavigationStart
            || routeEvent instanceof NavigationCancel
            || routeEvent instanceof NavigationError,
          errorMessage,
        };
      },
    );
  }
}
