import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { Address } from '@core/interfaces/address.interface';
import { SelectProductPopupData } from '@core/interfaces/select-product-popup-data';
import {
  BaseProductControlsEnum,
  editableConsumerProductFormFields,
  IEditableConsumerProductFormField,
} from '@core/utils/register-claim';
import { AddressType } from '@shared/modules/claim-dialogs/enums/address-type.enum';
import { FormMode } from '@shared/modules/claim-dialogs/enums/form-mode.enum';
import { ClaimFormConfig } from '@shared/modules/claim-dialogs/interfaces/claim-form-config.interface';

import { AppConstants } from '../constants/app.constants';
import { BaseConsumer } from '../interfaces/claims/claimDetails.interface';
import { IProductIncident, ProductInterface } from '../interfaces/claims/product.interface';
import { Plan } from '../interfaces/plan/plan.interface';
import { Purchase } from '../interfaces/quick-search/search-results.interface';
import { UserStore } from '../store/user/user.store';
import { DateValidation } from '../utils/form.util';

@Injectable({
  providedIn: 'root',
})
export class ClaimFormService {
  formConfig$: BehaviorSubject<ClaimFormConfig> = new BehaviorSubject<ClaimFormConfig>(this._returnDefaultFormConfig());

  constructor(
    private readonly userStore: UserStore,
    private readonly fb: UntypedFormBuilder,
    private readonly typedFb: FormBuilder,
  ) {}

  static ValidateConsumerPhones(control: AbstractControl): {consumerPhonesValid: boolean} {
    if (!control.get('mobilePhone').value && !control.get('homePhone').value && !control.get('workPhone').value) {
      return { consumerPhonesValid: true };
    } else {
      return null;
    }
  }

  static ValidateCoveredProducts(control: AbstractControl): {coveredProductsInvalid: boolean} {
    if (control.value.length > 0) {
      return null;
    } else {
      return { coveredProductsInvalid: true };
    }
  }

  static ValidateFirstLastName(minNameLength: number = 1) {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstLastNameRegex = new RegExp(`^[a-z]+.*[a-z]{${minNameLength - 1},}`, 'i');
      if (!!control.value && control.value.length > 0 && !firstLastNameRegex.test(control.value)) {
        return { firstLastNameInvalid: { minNameLength } };
      } else {
        return null;
      }
    };
  }

  static minLettersLength(minLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const minLettersLengthRegex = new RegExp(/[a-z]+/, 'ig');
      if (!!control.value && control.value.length > 0) {
        const onlyLetters = control.value.match(minLettersLengthRegex);
        if (!!onlyLetters && onlyLetters.join('').length >= minLength) {
          return null;
        } else {
          return { minLettersLength: { requiredLength: minLength, actualLength: control.value.length } };
        }
      } else {
        return null;
      }
    };
  }

  static phoneNumber(control: AbstractControl): {phoneNumberInvalid: {message: string}} {
    if (!!control.value && control.value.length > 0) {
      const digits = control.value.replace(/\D+/g, '');
      const phoneNumberRegex = new RegExp(`(\\d)+\\1{${digits.length - 1}}`, 'g');
      if (digits.length < 10 || phoneNumberRegex.test(digits)) {
        return {
          phoneNumberInvalid: {
            message: 'Phone number must be at least 10 digits and cannot be all the same digit',
          },
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  setFormMode(formMode: FormMode, data: SelectProductPopupData): void {
    let formConfig: ClaimFormConfig;

    if (formMode === FormMode.Scratch) {
      formConfig = this._returnDefaultFormConfig();
    } else {
      formConfig = { formMode, data };
    }

    this.formConfig$.next(formConfig);
  }

  getConsumerFields(
    consumer: Partial<BaseConsumer>,
    readonly: boolean = false,
    options: {excludedFields?: string[]; defaultPreferences?: boolean} = {},
  ): FormGroup {
    if (!options.excludedFields) {
      options.excludedFields = [];
    }
    if (options.defaultPreferences === undefined) {
      options.defaultPreferences = true;
    }

    const emailValidations = [Validators.pattern(AppConstants.emailRegEx), Validators.maxLength(100)];
    if (!readonly) {
      emailValidations.push(Validators.required);
    }
    const consumerFormData = {
      id: new FormControl(consumer.id || null),
      crmRefId: new FormControl(consumer.crmRefId || null),
      contactId: new FormControl(consumer.contactId || null),
      firstName: new FormControl(
        { value: consumer.firstName || null, disabled: readonly },
        Validators.compose([Validators.required, Validators.maxLength(50), ClaimFormService.ValidateFirstLastName()]),
      ),
      lastName: new FormControl(
        { value: consumer.lastName || null, disabled: readonly },
        Validators.compose([Validators.required, Validators.maxLength(50), ClaimFormService.ValidateFirstLastName(2)]),
      ),
      emailAddress: new FormControl(
        { value: consumer.emailAddress || null, disabled: readonly },
        emailValidations,
      ),
      mobilePhone: new FormControl(
        { value: consumer.mobilePhone || null, disabled: readonly },
        Validators.compose(this.getPhoneValidations(readonly)),
      ),
      homePhone: new FormControl(
        { value: consumer.homePhone || null, disabled: readonly },
        Validators.compose(this.getPhoneValidations(readonly)),
      ),
      workPhone: new FormControl(
        { value: consumer.workPhone || null, disabled: readonly },
        Validators.compose(this.getPhoneValidations(readonly)),
      ),
      preferredContactLanguage: new FormControl({
        value: consumer.preferredContactLanguage || (options.defaultPreferences ? 223940000 : null),
        disabled: readonly,
      }),
      preferredContactMethod: new FormControl({
        value: consumer.preferredContactMethod || (options.defaultPreferences ? 1 : null),
        disabled: readonly,
      }),
      preferredContactTime: new FormControl({
        value: consumer.preferredContactTime || (options.defaultPreferences ? 223940000 : null),
        disabled: readonly,
      }),
    };

    options.excludedFields.forEach(field => {
      delete consumerFormData[field];
    });

    const consumerFormGroup = new FormGroup(
      consumerFormData,
      readonly ? [] : [ClaimFormService.ValidateConsumerPhones],
    );

    // mark invalid data at the beginning
    if (!readonly) {
      if (consumer.firstName) {
        consumerFormGroup.get('firstName').markAsTouched();
      }
      if (consumer.lastName) {
        consumerFormGroup.get('lastName').markAsTouched();
      }
      if (consumer.emailAddress) {
        consumerFormGroup.get('emailAddress').markAsTouched();
      }
      if (consumer.mobilePhone) {
        consumerFormGroup.get('mobilePhone').markAsTouched();
      }
      if (consumer.homePhone) {
        consumerFormGroup.get('homePhone').markAsTouched();
      }
      if (consumer.workPhone) {
        consumerFormGroup.get('workPhone').markAsTouched();
      }
    }

    return consumerFormGroup;
  }

  getAddressFields(
    validatedAddress: Partial<Address>,
    addressType: AddressType,
    readonly: boolean = false,
  ): FormGroup {
    const formFields = {
      streetAddress1: new FormControl(
        { value: validatedAddress?.streetAddress1 || null, disabled: readonly },
        [Validators.required, Validators.maxLength(250)],
      ),
      streetAddress2: new FormControl(
        { value: validatedAddress?.streetAddress2 || null, disabled: readonly },
        Validators.maxLength(250),
      ),
      city: new FormControl(
        { value: validatedAddress?.city || null, disabled: readonly },
        [Validators.required, Validators.maxLength(80)],
      ),
      stateProvince: new FormControl(
        { value: validatedAddress?.stateProvince || null, disabled: readonly },
        [Validators.required, Validators.maxLength(50)],
      ),
      postalCode: new FormControl(
        { value: validatedAddress?.postalCode || null, disabled: readonly },
        [Validators.required, Validators.maxLength(20)],
      ),
      isValidated: new FormControl(false),
      addressValidationAttempted: new FormControl(false),
    };
    return new FormGroup(formFields);
  }

  getPurchaseFields(purchase: Partial<Purchase>, readonly: boolean = false): UntypedFormGroup {
    const purchaseForm: {[key: string]: AbstractControl} = {
      id: new FormControl(purchase.id || null),
      crmRefId: new FormControl(null),
      consumerAgreement: new FormControl({ value: purchase.consumerAgreement || null, disabled: readonly }),
      receiptNumber: new FormControl(
        {
          value: purchase.receiptNumber || null,
          disabled: readonly,
        },
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ),
      retailerId: new FormControl(
        { value: purchase.purchaseRetailerId || null, disabled: readonly },
        Validators.required,
      ),
      purchaseDate: new FormControl(
        { value: purchase.purchaseDate || null, disabled: readonly },
        Validators.compose([DateValidation, Validators.required]),
      ),
      deliveryDate: new FormControl(
        { value: purchase.deliveryDate || null, disabled: readonly },
        Validators.compose([DateValidation, Validators.required]),
      ),
      servicingRetailerId: new FormControl(
        { value: purchase.servicingRetailerId || null, disabled: readonly },
        Validators.required,
      ),
    };

    if (purchase.id) {
      purchaseForm.retailerName = new FormControl({ value: purchase.purchaseRetailerName || null, disabled: true });
      purchaseForm.servicingRetailerName = new FormControl({
        value: purchase.servicingRetailerName || null,
        disabled: true,
      });
    }
    return new FormGroup(purchaseForm);
  }

  getCoveredProductsArray(productControls: UntypedFormGroup[] = []): FormArray {
    return new FormArray(productControls, ClaimFormService.ValidateCoveredProducts);
  }

  getProductFields(product?: ProductInterface, readonly: boolean = false): UntypedFormGroup {
    const productFormGroup = this.fb.group(this._returnBaseProductControls(readonly));

    productFormGroup.addControl(
      'manufacturerId',
      this.fb.control({ value: null, disabled: readonly }, Validators.required),
    );
    productFormGroup.addControl('isPartOfPackage', this.fb.control({ value: null, disabled: readonly }));
    productFormGroup.addControl('partsAvailability', this.fb.control({ value: null, disabled: readonly }));
    productFormGroup.addControl('productAvailability', this.fb.control({ value: null, disabled: readonly }));
    productFormGroup.addControl('productIncidents', this.fb.array([this.getProductIncidentFields({})]));

    if (product.id) {
      productFormGroup.addControl('manufacturerName', this.fb.control({ value: null, disabled: true }));
    }

    productFormGroup.patchValue({
      ...product,
      productName: product.name || product.productName,
    });

    return productFormGroup;
  }

  getConsumerProductFields(product?: ProductInterface, readonly: boolean = false): UntypedFormGroup {
    const productFormGroup = this.fb.group(this._returnBaseProductControls(readonly, true));
    productFormGroup.addControl('productIncident', this.getConsumerProductIncidentFields({}));
    productFormGroup.patchValue({
      ...product,
      productName: product.name || product.productName,
    });
    if (productFormGroup.get(BaseProductControlsEnum.Id)?.value) {
      editableConsumerProductFormFields.forEach((fieldData: IEditableConsumerProductFormField) => {
        const control = productFormGroup.get(fieldData.fieldKey);
        if (control && fieldData.evaluateIfEditable(control.value)) {
          control.enable();
        }
      });
    }
    return productFormGroup;
  }

  getProductIncidentFields(productIncident: Partial<IProductIncident>): UntypedFormGroup {
    const productIncidentFormGroup = this.fb.group(this._returnBaseProductIncidentControls());

    productIncidentFormGroup.addControl('problemTypeSubcategoryId', this.fb.control(null));
    productIncidentFormGroup.addControl('retailerClassificationId', this.fb.control(null));

    productIncidentFormGroup.patchValue(productIncident);

    return productIncidentFormGroup;
  }

  getConsumerProductIncidentFields(productIncident: Partial<IProductIncident>): UntypedFormGroup {
    const consumerProductIncidentFormGroup = this.fb.group(this._returnBaseProductIncidentControls());
    consumerProductIncidentFormGroup.addControl('dateNoticed', this.fb.control(null, Validators.required));
    consumerProductIncidentFormGroup.addControl('causeTypeId', this.fb.control(null, Validators.required));
    consumerProductIncidentFormGroup.addControl('causeTypeName', this.fb.control({ value: null, disabled: true }));

    consumerProductIncidentFormGroup.patchValue(productIncident);

    return consumerProductIncidentFormGroup;
  }

  getConsumerPlanFields(consumerPlan: Partial<Plan>, readonly: boolean = false): FormGroup {
    return this.typedFb.group({
      crmRefId: new FormControl<string>(consumerPlan.crmRefId || null),
      clientRefObject: new FormControl<number>(consumerPlan.clientRefObject || null),
      receiptNumber: new FormControl<string>(
        { value: consumerPlan.receiptNumber || null, disabled: readonly },
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ),
      planPrice: new FormControl<number>(
        { value: consumerPlan.planPrice || null, disabled: readonly },
        [Validators.required, Validators.max(9999999999.99)],
      ),
      purchaseDate: new FormControl<string>({ value: consumerPlan.purchaseDate || null, disabled: readonly }, [
        Validators.required,
      ]),
      deliveryDate: new FormControl<string>({ value: consumerPlan.deliveryDate || null, disabled: readonly }, [
        Validators.required,
      ]),
    });
  }

  getSubmitterAndEscalationFields(values: Record<string, unknown[]>): FormGroup {
    const session = this.userStore.get('session');
    const submitterFormGroup = new FormGroup({
      submitterFirstName: new FormControl(
        session.userFirstName,
        Validators.compose([Validators.required, Validators.maxLength(100), ClaimFormService.ValidateFirstLastName()]),
      ),
      submitterLastName: new FormControl(
        session.userLastName,
        Validators.compose([Validators.required, Validators.maxLength(100), ClaimFormService.ValidateFirstLastName(2)]),
      ),
      submitterEmail: new FormControl(
        session.username,
        Validators.compose([
          Validators.pattern(AppConstants.emailRegEx),
          Validators.required,
          Validators.maxLength(100),
        ]),
      ),
      submitterPhone: new FormControl(
        session.phoneNumber,
        Validators.compose([Validators.maxLength(100), ClaimFormService.phoneNumber]),
      ),
      escalation: new FormControl(values.escalation || null),
    });

    // mark invalid data at the beginning
    if (session.userFirstName) {
      submitterFormGroup.get('submitterFirstName').markAsTouched();
    }
    if (session.userLastName) {
      submitterFormGroup.get('submitterLastName').markAsTouched();
    }
    if (session.username) {
      submitterFormGroup.get('submitterEmail').markAsTouched();
    }
    if (session.phoneNumber) {
      submitterFormGroup.get('submitterPhone').markAsTouched();
    }
    return submitterFormGroup;
  }

  isFormReadOnly(value: Record<string, unknown>): boolean {
    return !!value.id || !!value.crmRefId;
  }

  getPhoneValidations(readonly: boolean): ValidatorFn[] {
    const phoneValidations = [Validators.maxLength(50), ClaimFormService.phoneNumber];
    return readonly ? [] : phoneValidations;
  }

  private _returnBaseProductControls(readonly: boolean = false, isConsumer: boolean = false): Record<string, unknown[]> {
    const skuValidations = [Validators.maxLength(100)];
    const productDescriptionValidations = [Validators.maxLength(2000), Validators.required];
    const unitPriceValidations = [Validators.required, Validators.min(0.01)];
    const baseProductControls = {
      [BaseProductControlsEnum.Id]: [null],
      [BaseProductControlsEnum.CrmRefId]: [null],
      [BaseProductControlsEnum.ProductName]: [{ value: null, disabled: readonly }],
      [BaseProductControlsEnum.ProductDescription]: [{ value: '', disabled: readonly }, Validators.compose(productDescriptionValidations)],
      [BaseProductControlsEnum.SurfaceTypeId]: [{ value: null, disabled: readonly }, Validators.required],
      [BaseProductControlsEnum.SurfaceTypeName]: [{ value: null, disabled: true }],
      [BaseProductControlsEnum.DeliveryDate]: [{ value: null, disabled: readonly }, Validators.required],
      [BaseProductControlsEnum.IsPartOfPackage]: [{ value: false, disabled: readonly }],
      [BaseProductControlsEnum.UnitPrice]: [{ value: null, disabled: readonly }, Validators.compose(unitPriceValidations)],
    };
    const extendedProductControls = {
      productClassificationId: [{ value: null, disabled: readonly }, Validators.required],
      productTypeId: [{ value: null, disabled: readonly }, Validators.required],
      sku: [{ value: null, disabled: readonly }, Validators.compose(skuValidations)],
      serialNumber: [{ value: null, disabled: readonly }, Validators.maxLength(100)],
      unitPrice: [{ value: null, disabled: readonly }, Validators.compose(unitPriceValidations)],
    };

    if (!isConsumer) {
      skuValidations.push(Validators.required);
    }

    return isConsumer ? { ...baseProductControls } : { ...baseProductControls, ...extendedProductControls };
  }

  private _returnBaseProductIncidentControls(): Record<string, unknown[]> {
    return {
      id: [null],
      crmRefId: [null],
      problemTypeId: [null, Validators.compose([Validators.required])],
      problemTypeSubcategoryId: [{ value: null, disabled: true }],
      problemTypeName: [{ value: null, disabled: true }],
      damageDescription: ['', Validators.compose([Validators.required, Validators.maxLength(2000)])],
    };
  }

  private _returnDefaultFormConfig(): ClaimFormConfig {
    return {
      formMode: FormMode.Scratch,
      data: {
        products: [],
      },
    };
  }
}
