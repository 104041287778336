import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { AlertMessageComponent } from '@shared/components/alert-message/alert-message.component';
import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { FormTextareaComponent } from '@shared/components/form-textarea/form-textarea.component';
import { SwitchComponent } from '@shared/components/switch/switch.component';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import {
  ProblemTypeSelectComponent,
} from '@shared/modules/claim-controls/components/problem-type-select/problem-type-select.component';
import { SelectsModule } from '@shared/modules/selects/selects.module';
import {
  RetailerSelectComponent,
} from '@shared/modules/store-controls/components/retailer-select/retailer-select.component';
import { PipesModule } from '@shared/pipes/pipes.module';

import { ClaimControlsModule } from '../claim-controls/claim-controls.module';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressReadonlyComponent } from './components/address-readonly/address-readonly.component';
import { ConsumerFormComponent } from './components/consumer-form/consumer-form.component';
import { ConsumerProductFormComponent } from './components/consumer-product-form/consumer-product-form.component';
import { ConsumerProductIncidentFormComponent } from './components/consumer-product-incident-form/consumer-product-incident-form.component';
import { ConsumerReadonlyComponent } from './components/consumer-readonly/consumer-readonly.component';
import { ProductIncidentFormComponent } from './components/product-incident-form/product-incident-form.component';
import { ProductsFormComponent } from './components/products-form/products-form.component';
import { PurchaseFormComponent } from './components/purchase-form/purchase-form.component';
import { SubmitterAndEscalationFormComponent } from './components/submitter-and-escalation-form/submitter-and-escalation-form.component';

@NgModule({
  declarations: [
    AddressFormComponent,
    AddressReadonlyComponent,
    ConsumerFormComponent,
    ConsumerProductFormComponent,
    ConsumerProductIncidentFormComponent,
    ConsumerReadonlyComponent,
    ProductIncidentFormComponent,
    ProductsFormComponent,
    PurchaseFormComponent,
    SubmitterAndEscalationFormComponent,
  ],
  imports: [
    AlertMessageComponent,
    AsyncPipe,
    ClaimControlsModule,
    DatePipe,
    FormDateComponent,
    FormInputComponent,
    FormTextareaComponent,
    MatIconModule,
    NgIf,
    PipesModule,
    ProblemTypeSelectComponent,
    ReactiveFormsModule,
    RetailerSelectComponent,
    SelectsModule,
    SwitchComponent,
    ThFormFieldBlockComponent,
    ThFormFieldComponent,
  ],
  exports: [
    AddressFormComponent,
    AddressReadonlyComponent,
    ConsumerFormComponent,
    ConsumerProductFormComponent,
    ConsumerProductIncidentFormComponent,
    ConsumerReadonlyComponent,
    ProductIncidentFormComponent,
    ProductsFormComponent,
    PurchaseFormComponent,
    SubmitterAndEscalationFormComponent,
  ],
})
export class ClaimFormModule {}
