import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { BaseFormControlElement } from '@core/base/base-form-control-element';

@Component({
  selector: 'app-switch',
  standalone: true,
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    ReactiveFormsModule,
    MatIconModule,
  ],
})
export class SwitchComponent extends BaseFormControlElement {
  @Input() type: string;
  @Input() disabled = false;
  @Input() spin = false;
  @Input() title: string;
  @Input() fieldName = '';

  constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl);
  }
}
